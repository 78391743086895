import React from 'react';
import PropTypes from 'prop-types';

import { ComponentWrapper, ComponentGutter } from 'components/common';
import SavingsSlider from 'components/SavingsSlider';
import { TrustpilotSection, PortalSection } from 'components/CMS';
import getTrustpilotRating from 'utils/getTrustpilotRating';

const RenderComponent = ({ componentName, componentProps, trustpilotData }) => {
  const rating = getTrustpilotRating(trustpilotData);

  switch (componentName) {
    case 'PortalSection': {
      return (
        <>
          <ComponentWrapper>
            <PortalSection body={componentProps?.body} />
          </ComponentWrapper>
        </>
      );
    }
    case 'TrustpilotSection': {
      const { showStars, showType, text } = componentProps;
      return (
        <>
          <ComponentWrapper>
            <TrustpilotSection
              showStars={showStars}
              showType={showType}
              text={text}
              rating={rating ? rating : null}
            />
          </ComponentWrapper>
        </>
      );
    }
    case 'SavingsSlider': {
      const { title, subtitle } = componentProps;
      return (
        <ComponentWrapper>
          <ComponentGutter>
            <SavingsSlider title={title} subtitle={subtitle} />
          </ComponentGutter>
        </ComponentWrapper>
      );
    }
    default:
      return null;
  }
};

RenderComponent.propTypes = {
  componentName: PropTypes.string.isRequired,
  componentProps: PropTypes.shape({
    showStars: PropTypes.string,
    showType: PropTypes.string,
    text: PropTypes.string,
    body: PropTypes.string,
    showAfter: PropTypes.string,
    subtitle: PropTypes.string,
    title: PropTypes.string,
  }),
  trustpilotData: PropTypes.shape({
    trustPilotPublicBusinessUnit: PropTypes.shape({
      numberOfReviews: PropTypes.shape({
        total: PropTypes.number,
      }),
    }),
  }),
};

RenderComponent.defaultProps = {
  componentProps: {
    showStars: null,
    showType: null,
    text: null,
    body: null,
    showAfter: null,
    subtitle: null,
    title: null,
  },
};

export default RenderComponent;
