import React from 'react';
import {
  Box,
  Flex,
  Text,
  HideBreakpoint,
  ShowBreakpoint,
} from '@strikelabs/luna';
import PropTypes from 'prop-types';

import { Subheading, Container } from 'components/common';
import TrustpilotStars from 'components/Icons/TrustpilotStars';

import Review from './Review';

import {
  TileContainer,
  ColumnContainer,
  SlideText,
  Column,
  DetailsText,
  WideTileAvatar,
} from './style';

const renderReviews = reviewArr => {
  return reviewArr.map((review, idx) => {
    const {
      backgroundColor,
      avatar,
      author,
      customerType,
      date,
      location,
      heading,
      copy,
      savings,
      fullWidth,
      rating,
    } = review;

    if (fullWidth) {
      return (
        <ColumnContainer
          backgroundColor={backgroundColor}
          addMargin={!!avatar}
          key={idx}
        >
          <HideBreakpoint breakpoint="tablet">
            <WideTileAvatar src={avatar?.fluid?.src} />
          </HideBreakpoint>
          <Column flexDirection="column" alignItems="center">
            <ShowBreakpoint breakpoint="tablet">
              <WideTileAvatar src={avatar?.fluid?.src} />
            </ShowBreakpoint>
            <Box mt={[2, 2, 4, 0]} textAlign="center">
              {author && <DetailsText>{author}</DetailsText>}
              {location && (
                <DetailsText textColor={backgroundColor}>
                  {location}
                </DetailsText>
              )}
              {customerType && (
                <DetailsText textColor={backgroundColor}>
                  {customerType}
                </DetailsText>
              )}
              {date && (
                <DetailsText textColor={backgroundColor}>{date}</DetailsText>
              )}
            </Box>
          </Column>
          <Column>
            <Flex flexDirection="column">
              <HideBreakpoint breakpoint="tablet">
                {rating && <TrustpilotStars width="90px" />}
              </HideBreakpoint>
              <ShowBreakpoint breakpoint="tablet">
                {rating && <TrustpilotStars width="120px" />}
              </ShowBreakpoint>
              {heading && (
                <SlideText mt={1} as="h2">
                  {heading}
                </SlideText>
              )}
              {copy && (
                <Box mt={2} mb={2}>
                  <SlideText ext>{copy}</SlideText>
                </Box>
              )}
            </Flex>
          </Column>
        </ColumnContainer>
      );
    }

    return (
      <Review
        key={idx}
        backgroundColor={backgroundColor}
        avatar={avatar?.fluid?.src}
        author={author}
        customerType={customerType}
        date={date}
        location={location}
        heading={heading}
        quote={copy}
        savings={savings}
        rating={rating}
      />
    );
  });
};

const ReviewGrid = ({ data }) => {
  const { heading, subheading, items } = data;

  return (
    <Container>
      {(subheading || heading) && (
        <Box>
          {subheading && <Subheading>{subheading}</Subheading>}
          {heading && (
            <Box mt={1} mb={2}>
              <Text as="h2" styleType="h1" ext>
                {heading}
              </Text>
            </Box>
          )}
        </Box>
      )}
      <TileContainer>{renderReviews(items)}</TileContainer>
    </Container>
  );
};

ReviewGrid.propTypes = {
  data: PropTypes.shape({
    heading: PropTypes.string,
    subheading: PropTypes.string,
    id: PropTypes.string.isRequired,
    items: PropTypes.array,
    rating: PropTypes.number,
  }),
};

ReviewGrid.defaultProps = {
  data: {
    heading: '',
    subheading: '',
    items: [],
    rating: 5,
  },
};

export default ReviewGrid;
