/* eslint-disable react/display-name */
import React from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { Text, Box } from '@strikelabs/luna';
import PropTypes from 'prop-types';
import { getImage, GatsbyImage } from 'gatsby-plugin-image';

import useContentfulAsset from 'hooks/useContentfulAsset';

const options = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (_node, children) => (
      <Text mb={2} styleType="body" ext>
        {children}
      </Text>
    ),
    [INLINES.ASSET_HYPERLINK]: _node => {
      const asset = useContentfulAsset(_node.data.target.sys.id);

      const assetValue = asset?.node.title;
      const assetUrl = asset?.node?.file.url;

      return (
        <a href={assetUrl} target="_blank" rel="noopener noreferrer">
          {assetValue}
        </a>
      );
    },
    [INLINES.HYPERLINK]: _node => {
      const { content, data } = _node;
      if (data?.uri.startsWith('https://strike.co.uk')) {
        return <a href={`${_node.data.uri}`}>{_node.content[0].value}</a>;
      }
      return (
        <a href={`${data?.uri}`} rel="noopener noreferrer" target="_blank">
          {content[0]?.value}
        </a>
      );
    },
    [BLOCKS.HEADING_1]: (_node, children) => (
      <Text as="h1" ext>
        {children}
      </Text>
    ),
    [BLOCKS.HEADING_2]: (_node, children) => (
      <Text as="h2" ext>
        {children}
      </Text>
    ),
    [BLOCKS.HEADING_3]: (_node, children) => (
      <Text as="h3" ext>
        {children}
      </Text>
    ),
    [BLOCKS.HEADING_4]: (_node, children) => (
      <Text as="h4" ext>
        {children}
      </Text>
    ),
    [BLOCKS.EMBEDDED_ASSET]: _node => {
      const asset = useContentfulAsset(_node.data.target.sys.id);

      if (asset?.node) {
        const imagePath = getImage(asset.node);
        return (
          <Box mb={1}>
            <GatsbyImage image={imagePath} alt={asset.title} />
          </Box>
        );
      }
    },
  },
  renderText: text => {
    return text.split(/\\n/i).reduce((children, textSegment, index) => {
      return [...children, index > 0 && <br key={index} />, textSegment];
    }, []);
  },
};

const RichTextRenderer = ({ document }) => {
  const data = document;
  return <span>{documentToReactComponents(data, options)}</span>;
};

RichTextRenderer.propTypes = {
  document: PropTypes.shape({}).isRequired,
};

export default RichTextRenderer;
