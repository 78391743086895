import React from 'react';
import idx from 'idx';
import PropTypes, { string } from 'prop-types';

import { ColumnBlockSection, AccordionSection } from 'components/CMS';
import { ComponentWrapper } from 'components/common';
import RichTextRenderer from 'components/RichTextRenderer';

/*
 * This component will either return an 'Accordian' or 'Column' based layout based
 * based on the 'Display' field provided in the Contentful 'List' component.
 */

const RenderDisplay = ({ sectionProps }) => {
  const { display, items, heading, subheading } = sectionProps;

  if (display) {
    switch (display[0]) {
      case 'Columns': {
        let isImage;

        const mappedColumns = items.reduce((acc, item) => {
          const { __typename, id } = item;

          if (__typename === 'ContentfulText') {
            isImage = !!item?.image;
            const content = idx(item, _item => JSON.parse(_item.body.raw));

            const link = item.cta
              ? {
                  text: item.cta[0]?.ctaText || 'Learn more here',
                  url: item.cta[0]?.ctaUrl || '/#',
                }
              : null;

            return [
              ...acc,
              {
                columnCount: items.length,
                image: item.image,
                heading: item.headline,
                content: <RichTextRenderer key={id} document={content} />,
                link,
              },
            ];
          }

          if (__typename === 'ContentfulNewTextSection') {
            isImage = !!item?.images?.image;
            const content = idx(item, _item => JSON.parse(_item.body.raw));

            const link = item.cta
              ? {
                  text: item.cta[0]?.ctaText || 'Learn more here',
                  url: item.cta[0]?.ctaUrl || '/#',
                }
              : null;

            return [
              ...acc,
              {
                columnCount: items.length,
                image: item?.images?.image[0],
                heading: item.headline,
                content: <RichTextRenderer key={id} document={content} />,
                link,
              },
            ];
          }

          const link = {
            text: 'Learn more here',
            url: `/${item.slug}`,
          };

          return [
            ...acc,
            {
              columnCount: items.length,
              image: item.metaImage,
              heading: item.metaTitle,
              content: item.metaDescription,
              link,
            },
          ];
        }, []);

        const getColumnsPerRow = () => {
          if (mappedColumns.length >= 2 && mappedColumns.length <= 4) {
            return mappedColumns.length;
          }

          return 4;
        };

        /*
         * Depending whether the content supplied in the 'Items' field for the 'List'
         * contains any form of imagery, it'll render the image variation of the 'Column Block'.
         *
         * Note: All items supplied must contain an image for the image section to render,
         * otherwise it defaults to regular.
         */

        return (
          <>
            {isImage ? (
              <ColumnBlockSection
                isImage={true}
                headline={heading}
                subhead={subheading}
                columnsPerRow={getColumnsPerRow()}
                columns={mappedColumns}
              />
            ) : (
              <ColumnBlockSection
                headline={heading}
                subhead={subheading}
                columnsPerRow={getColumnsPerRow()}
                columns={mappedColumns}
              />
            )}
          </>
        );
      }
      case 'Accordion': {
        const mappedAccordions = items?.reduce((acc, item, index) => {
          const { __typename, id } = item;

          if (__typename === 'ContentfulText') {
            const content = idx(item, _item => JSON.parse(_item.body.raw));

            return [
              ...acc,
              {
                id,
                startOpen: index === 0 ? true : false,
                label: item.headline,
                content: <RichTextRenderer key={id} document={content} />,
              },
            ];
          }

          return [
            ...acc,
            {
              startOpen: index === 0 ? true : false,
              id,
              label: item.metaTitle,
              content: item.metaDescription,
            },
          ];
        }, []);

        return (
          <ComponentWrapper>
            <AccordionSection
              accordions={mappedAccordions}
              headline={heading}
            />
          </ComponentWrapper>
        );
      }
      default:
        return null;
    }
  }

  return;
};

RenderDisplay.propTypes = {
  sectionProps: PropTypes.shape({
    display: PropTypes.arrayOf(PropTypes.string),
    heading: PropTypes.string,
    subheading: PropTypes.string,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        __typename: PropTypes.string,
        id: PropTypes.string,
        slug: PropTypes.string,
        metaTitle: PropTypes.string,
        metaDescription: PropTypes.string,
        heroCta: PropTypes.arrayOf(
          PropTypes.shape({
            ctaText: string,
            ctaUrl: string,
          })
        ),
        cta: PropTypes.arrayOf(
          PropTypes.shape({
            ctaText: string,
            ctaUrl: string,
          })
        ),
        metaImage: PropTypes.shape({}),
      })
    ).isRequired,
  }).isRequired,
};

export default RenderDisplay;
