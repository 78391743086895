import React from 'react';
import PropTypes from 'prop-types';

const TrustPilotStars = ({ primary, secondary, width }) => (
  <svg
    version="1.1"
    width={width}
    viewBox="0 0 512 96"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect fill={primary} width="96" height="96" />
    <rect fill={primary} x="104" width="96" height="96" />
    <rect fill={primary} x="208" width="96" height="96" />
    <rect fill={primary} x="312" width="96" height="96" />
    <g transform="translate(416)">
      <rect fill={primary} width="96" height="96" />
    </g>
    <path
      fill={secondary}
      d="m48 64.7 14.6-3.7 6.1 18.8-20.7-15.1zm33.6-24.3h-25.7l-7.9-24.2-7.9 24.2h-25.7l20.8 15-7.9 24.2 20.8-15 12.8-9.2 20.7-15z"
    />
    <path
      fill={secondary}
      d="m152 64.7 14.6-3.7 6.1 18.8-20.7-15.1zm33.6-24.3h-25.7l-7.9-24.2-7.9 24.2h-25.7l20.8 15-7.9 24.2 20.8-15 12.8-9.2 20.7-15z"
    />
    <path
      fill={secondary}
      d="m256 64.7 14.6-3.7 6.1 18.8-20.7-15.1zm33.6-24.3h-25.7l-7.9-24.2-7.9 24.2h-25.7l20.8 15-7.9 24.2 20.8-15 12.8-9.2 20.7-15z"
    />
    <path
      fill={secondary}
      d="m360 64.7 14.6-3.7 6.1 18.8-20.7-15.1zm33.6-24.3h-25.7l-7.9-24.2-7.9 24.2h-25.7l20.8 15-7.9 24.2 20.8-15 12.8-9.2 20.7-15z"
    />
    <path
      fill={secondary}
      d="m464 64.7 14.6-3.7 6.1 18.8-20.7-15.1zm33.6-24.3h-25.7l-7.9-24.2-7.9 24.2h-25.7l20.8 15-7.9 24.2 20.8-15 12.8-9.2 20.7-15z"
    />
  </svg>
);

TrustPilotStars.propTypes = {
  primary: PropTypes.string,
  secondary: PropTypes.string,
  width: PropTypes.string,
};

TrustPilotStars.defaultProps = {
  primary: '#1AB075',
  secondary: '#FFF',
  width: '',
};

export default TrustPilotStars;
