import { graphql, useStaticQuery } from 'gatsby';

/**
 *
 * @param {string} assetUrl
 * @returns {string} A contentful asset, e.g. image or hyperlink.
 *
 * Hook for fetching and filtering a Contentful asset based on
 * an asset ID.
 */
const useContentfulAsset = assetUrl => {
  const { assets } = useStaticQuery(
    graphql`
      query {
        assets: allContentfulAsset {
          edges {
            node {
              contentful_id
              title
              gatsbyImageData(
                formats: [WEBP, AUTO]
                placeholder: BLURRED
                quality: 80
                width: 1080
                layout: FULL_WIDTH
              )
              file {
                url
              }
            }
          }
        }
      }
    `
  );

  const asset = assets.edges.find(
    ({ node }) => node.contentful_id === assetUrl
  );

  return asset;
};

export default useContentfulAsset;
