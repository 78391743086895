import styled, { css } from 'styled-components';
import { rem } from 'polished';
import { Box, Text, Flex } from '@strikelabs/luna';

export const Tile = styled(Box)`
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: stretch;
  padding: ${({ theme }) => theme.spacing.lg};
  background-color: ${({ theme }) => theme.colors.backgroundLight};

  ${({ theme }) => theme.media.tablet} {
    max-width: 49%;
    padding: ${({ theme }) => theme.spacing.xl};
  }

  ${({ addMargin }) => {
    if (addMargin) {
      return css`
        margin-top: ${rem(70)};

        ${({ theme }) => theme.media.smallTablet} {
          margin-top: ${rem(85)};
        }
      `;
    }
  }}

  ${({ backgroundColor }) => {
    switch (backgroundColor) {
      case 'green':
        return css`
          background-color: ${({ theme }) => theme.colors.accent};
          color: ${({ theme }) => theme.colors.bodyText};
        `;
      case 'black':
        return css`
          background-color: ${({ theme }) => theme.colors.darkMenuHighlight};
          color: ${({ theme }) => theme.colors.backgroundLight};
        `;
      case 'white':
        return css`
          background-color: ${({ theme }) => theme.colors.background};
          color: ${({ theme }) => theme.colors.bodyText};
        `;
      default:
        return;
    }
  }}
`;

export const TileContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: ${({ theme }) => theme.spacing.rg};
  flex-wrap: wrap;

  div:last-child {
    margin-bottom: 0;
  }
`;

export const ColumnContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: ${({ theme }) => theme.spacing.lg};
  margin: ${({ theme }) => `${theme.spacing.rg} 0`};
  background-color: ${({ theme }) => theme.colors.backgroundLight};

  ${({ theme }) => theme.media.desktop} {
    flex-direction: row;
    padding: ${({ theme }) => theme.spacing.xl};
  }

  ${({ addMargin }) => {
    if (addMargin) {
      return css`
        margin: ${rem(70)} 0 ${rem(40)};

        ${({ theme }) => theme.media.smallTablet} {
          margin: ${rem(85)} 0 ${rem(50)};
        }

        ${({ theme }) => theme.media.tablet} {
          margin: ${({ theme }) => `${theme.spacing.xl} 0`};
        }
      `;
    }
  }}

  ${({ backgroundColor }) => {
    switch (backgroundColor) {
      case 'green':
        return css`
          background-color: ${({ theme }) => theme.colors.accent};
          color: ${({ theme }) => theme.colors.bodyText};
        `;
      case 'black':
        return css`
          background-color: ${({ theme }) => theme.colors.darkMenuHighlight};
          color: ${({ theme }) => theme.colors.backgroundLight};
        `;
      case 'white':
        return css`
          background-color: ${({ theme }) => theme.colors.background};
          color: ${({ theme }) => theme.colors.bodyText};
        `;
      default:
        return;
    }
  }}
`;

export const Column = styled(Flex)`
  position: relative;
  width: 100%;
  margin-bottom: ${({ theme }) => theme.spacing.lg};

  ${({ theme }) => theme.media.desktop} {
    width: 50%;
    margin-bottom: 0;
  }
`;

export const Avatar = styled.img`
  position: absolute;
  left: 50%;
  top: 0;
  z-index: 1;
  width: ${rem(100)};
  height: ${rem(100)};
  object-fit: cover;
  transform: translateY(-50%) translateX(-50%);
  padding-top: ${rem(1)};
  border-radius: 100%;

  ${({ theme }) => theme.media.smallTablet} {
    width: ${rem(130)};
    height: ${rem(130)};
  }
`;

export const WideTileAvatar = styled(Avatar)`
  ${({ theme }) => theme.media.tablet} {
    position: relative;
    left: unset;
    transform: unset;
    width: ${rem(180)};
    height: ${rem(180)};
  }
`;

export const SlideText = styled(Text).attrs({
  ext: true,
})`
  color: inherit;

  ${({ styleType }) => {
    if (styleType === 'intro') {
      return css`
        line-height: ${rem(22)};
      `;
    }
  }}
`;

export const DetailsText = styled(SlideText).attrs({ styleType: 'caption' })`
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: ${rem(2)};

  ${({ textColor }) => {
    switch (textColor) {
      case 'green':
        return css`
          color: ${({ theme }) => theme.colors.captionText};
        `;
      case 'black':
        return css`
          color: ${({ theme }) => theme.colors.background};
        `;
      case 'white':
        return css`
          color: ${({ theme }) => theme.colors.captionText};
        `;
      case 'grey':
        return css`
          color: ${({ theme }) => theme.colors.captionText};
        `;
    }
  }};
`;

export const SavingsText = styled(SlideText)`
  font-weight: 500;

  ${({ theme }) => theme.media.smallTablet} {
    font-weight: 600;
  }

  ${({ enlarge }) => {
    if (enlarge) {
      return css`
        font-weight: 600;
        font-size: ${rem(18)};

        ${({ theme }) => theme.media.smallTablet} {
          font-size: ${rem(22)};
          line-height: ${rem(28)};
        }
      `;
    }
  }};
`;
