import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';
import { Header, Footer, Box } from '@strikelabs/luna';
import { useAuth, useWebLogin, useWebSend } from '@strikelabs/vega';

import CookieBanner from 'components/CookieBanner';
import GlobalStyle from 'theme/global';
import themeV2 from 'theme';

import { Container } from './style';

const DynamicLayout = ({ children }) => {
  const { user, logout } = useAuth();

  const { handleLogout } = useWebLogin({
    onLogout: logout,
    useSendHook: useWebSend,
  });

  return (
    <ThemeProvider theme={themeV2}>
      <GlobalStyle />
      <CookieBanner url="/legal/privacy-policy" />
      <Header
        hubUrl={`${process.env.GATSBY_HUB_URL}`}
        authUrl={`${process.env.GATSBY_AUTH_URL}`}
        profile={user}
        handleLogout={handleLogout}
      />
      <Container>{children}</Container>
      <Box mt={[5, 5, 6, 6]} />
      <Footer showPBLink />
    </ThemeProvider>
  );
};

DynamicLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default DynamicLayout;
