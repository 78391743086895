import React from 'react';
import { identifier } from '@strikelabs/pax';
import { Box, ButtonAnimated, Flex } from '@strikelabs/luna';
import PropTypes from 'prop-types';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import { Subheading, ComponentHeadline } from 'components/common';

import {
  Container,
  Content,
  Inner,
  Body,
  Buttons,
  ImageWrapper,
  AlignmentWrapper,
  ImageContainer,
} from './style';

const renderImages = ({ images, id, maxWidth, imageAlignment }) => {
  return images.image.map((img, idx) => {
    const imagePath = getImage(img);

    if (images.length === 1) {
      if (imageAlignment === 'center') {
        return (
          <ImageContainer
            key={`img-${id}-${idx}`}
            mt={[2, 2, 2, 0]}
            maxImageWidth={maxWidth}
          >
            <GatsbyImage
              key={id}
              image={imagePath}
              alt={img.alt}
              objectFit="cover"
              {...identifier({ name: 'text-section-image', id })}
            />
          </ImageContainer>
        );
      }

      return (
        <ImageContainer
          key={`img-${id}-${idx}`}
          mt={[2, 2, null, null]}
          minHeight={[250, 250, 350, 450]}
        >
          <GatsbyImage
            key={id}
            image={imagePath}
            alt={img.alt}
            objectFit="cover"
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              bottom: 0,
            }}
            {...identifier({ name: 'text-section-image', id })}
          />
        </ImageContainer>
      );
    }

    if (imageAlignment === 'center') {
      return (
        <Flex
          key={`img-${id}-${idx}`}
          mt={[2, 2, 2, 0]}
          style={{ position: 'relative' }}
          justifyContent="center"
        >
          <ImageContainer maxImageWidth={maxWidth}>
            <GatsbyImage
              key={id}
              image={imagePath}
              alt={img.alt}
              style={{ width: '100%' }}
              {...identifier({ name: 'text-section-image', id })}
            />
          </ImageContainer>
        </Flex>
      );
    }

    return (
      <ImageContainer
        key={`img-${id}-${idx}`}
        minHeight={[250, 250, 350, 450]}
        mt={[
          idx === 0 ? 2 : 2,
          idx === 0 ? 2 : 2,
          idx === 0 ? 2 : 2,
          idx === 0 ? 0 : 2,
        ]}
      >
        <GatsbyImage
          key={id}
          image={imagePath}
          alt={img.alt}
          objectFit="cover"
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            bottom: 0,
          }}
          {...identifier({ name: 'text-section-image', id })}
        />
      </ImageContainer>
    );
  });
};

const TextSectionNew = ({
  background,
  subhead,
  headline,
  body,
  supplementaryBody,
  images,
  imageAlignment,
  primaryCta,
  secondaryCta,
  textAlignment,
  id,
  spaced,
  imageMaxWidth,
}) => {
  const hasCta = primaryCta || secondaryCta;

  return (
    <Container background={background}>
      <Inner hasImage={!!images} imageAlignment={imageAlignment.x}>
        <AlignmentWrapper alignment={textAlignment}>
          <Content>
            {subhead && (
              <Subheading {...identifier({ name: 'text-section-subhead', id })}>
                {subhead}
              </Subheading>
            )}
            {headline && (
              <ComponentHeadline
                centeredUnderline={!images}
                {...identifier({ name: 'text-section-headline', id })}
              >
                {headline}
              </ComponentHeadline>
            )}
            {body && (
              <Body
                hasImage={!!images}
                {...identifier({ name: 'text-section-body', id })}
              >
                {body}
              </Body>
            )}
          </Content>
        </AlignmentWrapper>
        {images && (
          <ImageWrapper imageAlignment={imageAlignment.x} spaced={spaced}>
            <AlignmentWrapper alignment={imageAlignment.y}>
              {renderImages({
                images: images,
                id,
                maxWidth: imageMaxWidth,
                imageAlignment: imageAlignment.x,
                alt: images.title,
              })}
            </AlignmentWrapper>
          </ImageWrapper>
        )}
      </Inner>
      {supplementaryBody && (
        <Box mt={2}>
          <Body
            hasImage={!!images}
            {...identifier({ name: 'text-section-supplementary-body', id })}
          >
            {supplementaryBody}
          </Body>
        </Box>
      )}
      {hasCta && (
        <Buttons hasImage={!!images}>
          {primaryCta && (
            <Box mb={['0px', 0, 0, '0px', '0px']} mr={1}>
              <ButtonAnimated
                type="button"
                to={primaryCta.url}
                {...identifier({
                  name: 'text-section-cta',
                  suffix: 'primary',
                  id,
                })}
              >
                {primaryCta.label}
              </ButtonAnimated>
            </Box>
          )}
          {secondaryCta && (
            <ButtonAnimated
              type="button"
              to={secondaryCta.url}
              {...identifier({
                name: 'text-section-cta',
                suffix: 'secondary',
                id,
              })}
            >
              {secondaryCta.label}
            </ButtonAnimated>
          )}
        </Buttons>
      )}
    </Container>
  );
};

TextSectionNew.propTypes = {
  background: PropTypes.string,
  subhead: PropTypes.string,
  headline: PropTypes.string,
  supplementaryBody: PropTypes.node,
  body: PropTypes.node,
  images: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  imageAlignment: PropTypes.shape({
    x: PropTypes.string,
    y: PropTypes.string,
  }),
  imageMaxWidth: PropTypes.number,
  textAlignment: PropTypes.string,
  primaryCta: PropTypes.shape({
    label: PropTypes.string,
    url: PropTypes.string,
  }),
  secondaryCta: PropTypes.shape({
    label: PropTypes.string,
    url: PropTypes.string,
  }),
  id: PropTypes.string,
  spaced: PropTypes.bool,
};

export default TextSectionNew;
