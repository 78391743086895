import React from 'react';
import idx from 'idx';
import { Helmet } from 'react-helmet';

import { BlockQuoteWrapper, TextSectionContainer } from 'components/common';
import { BlockQuoteSection } from 'components/CMS';

import ReviewGrid from 'components/ReviewGrid';
import TextSectionNew from 'components/CMS/TextSectionNew';
import RenderDisplay from 'components/RenderDisplay';
import RenderComponent from 'components/RenderComponent';
import { LegacyTextSection } from 'components/CMS';
import RichTextRenderer from 'components/RichTextRenderer';

const getSection = ({ section, trustpilotData }) => {
  const { id, ...sectionProps } = section;

  switch (section.__typename) {
    case 'ContentfulText': {
      const {
        subheading,
        headline,
        showWatermark,
        body,
        cta,
        imageAlignment,
        image,
      } = sectionProps;

      const primaryCta =
        cta && cta[0] ? { url: cta[0].ctaUrl, label: cta[0].ctaText } : null;

      const secondaryCta =
        cta && cta[1] ? { url: cta[1].ctaUrl, label: cta[1].ctaText } : null;

      const bodyJson = idx(body, _body => JSON.parse(_body.raw));

      const textImage = image && image.gatsbyImageData;

      const imageAlign = imageAlignment ? 'left' : 'right';

      return (
        <TextSectionContainer>
          <LegacyTextSection
            key={id}
            id={id}
            body={<RichTextRenderer document={bodyJson} />}
            headline={headline}
            subhead={subheading}
            primaryCta={primaryCta}
            secondaryCta={secondaryCta}
            imageAlignment={imageAlign}
            showWatermark={showWatermark}
            image={textImage}
          />
        </TextSectionContainer>
      );
    }
    case 'ContentfulNewTextSection': {
      const {
        subheading,
        headline,
        body,
        supplementaryBody,
        cta,
        images,
        textAlignment,
      } = sectionProps;

      const primaryCta =
        cta && cta[0] ? { url: cta[0].ctaUrl, label: cta[0].ctaText } : null;

      const secondaryCta =
        cta && cta[1] ? { url: cta[1].ctaUrl, label: cta[1].ctaText } : null;

      const bodyJson = idx(body, _body => JSON.parse(_body.raw));
      const supplementaryBodyJson = supplementaryBody
        ? idx(supplementaryBody, _supplementaryBody =>
            JSON.parse(_supplementaryBody.raw)
          )
        : null;

      const imageAlignment =
        images?.horizontalAlignment || images?.verticalAlignment
          ? { x: images.horizontalAlignment, y: images.verticalAlignment }
          : {};

      return (
        <TextSectionContainer>
          <TextSectionNew
            key={id}
            id={id}
            body={<RichTextRenderer document={bodyJson} />}
            supplementaryBody={
              <RichTextRenderer document={supplementaryBodyJson} />
            }
            headline={headline}
            subhead={subheading}
            primaryCta={primaryCta}
            secondaryCta={secondaryCta}
            imageAlignment={imageAlignment}
            images={images}
            textAlignment={textAlignment}
            imageMaxWidth={images?.maxWidth}
          />
        </TextSectionContainer>
      );
    }
    case 'ContentfulList': {
      return <RenderDisplay sectionProps={sectionProps} />;
    }
    case 'ContentfulComponent': {
      const { componentName, props } = sectionProps;
      return (
        <RenderComponent
          componentName={componentName}
          componentProps={props}
          trustpilotData={trustpilotData}
        />
      );
    }
    case 'ContentfulQuote': {
      const { cite, quote } = sectionProps;
      return (
        <BlockQuoteWrapper>
          <BlockQuoteSection key={id} author={cite} id={id}>
            {quote}
          </BlockQuoteSection>
        </BlockQuoteWrapper>
      );
    }
    case 'ContentfulSchema': {
      const { body } = sectionProps;

      const handleJson = content => {
        try {
          const parsedJson = JSON.parse(content);
          return JSON.stringify(parsedJson);
        } catch (err) {
          console.error(err);
        }
      };

      return (
        <Helmet>
          <script type="application/ld+json">
            {handleJson(body.internal.content)}
          </script>
        </Helmet>
      );
    }
    case 'ContentfulReviewsList': {
      return <ReviewGrid data={sectionProps} />;
    }
    default:
      return null;
  }
};

const renderSections = ({ sections, trustpilotData }) => {
  if (!sections || !sections.length) {
    return [];
  }

  return sections.map(section => getSection({ section, trustpilotData }));
};

export default renderSections;
