import styled, { css } from 'styled-components';
import { rem } from 'polished';
import { Box } from '@strikelabs/luna';

export const Container = styled.div`
  background-color: ${({ background, theme }) =>
    background === 'grey' ? theme.colors.backgroundLight : 'transparent'};
  max-width: ${({ theme }) => theme.maxWidth};
`;

export const Content = styled.div`
  text-align: left;
  width: 100%;
`;

export const ImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;

  ${({ theme }) => theme.media.tablet} {
    ${({ imageAlignment, theme }) => {
      switch (imageAlignment) {
        case 'left':
          return css`
            margin: 0 ${theme.spacing.lg} 0 0;
          `;
        case 'right':
          return css`
            margin: 0 0 0 ${theme.spacing.lg};
          `;
        default:
          return css`
            margin: 0 ${theme.spacing.lg} 0 0;
          `;
      }
    }}
  }

  ${({ spaced }) =>
    spaced
      ? css`
          width: ${rem(1500)};
          margin-right: ${rem(75)};
        `
      : null}
`;

export const ImageContainer = styled(Box)`
  position: relative;
  width: 100%;
  height: 100%;

  ${({ theme }) => theme.media.tablet} {
    width: ${({ maxImageWidth }) => `${maxImageWidth}%`};
  }
`;

export const Inner = styled.div`
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.media.tablet} {
    ${({ imageAlignment }) => {
      switch (imageAlignment) {
        case 'left':
          return css`
            flex-direction: row-reverse;
          `;
        case 'right':
          return css`
            flex-direction: row;
          `;
        case 'center':
          return css`
            flex-direction: column;
          `;
        default:
          return css`
            flex-direction: row-reverse;
          `;
      }
    }}
  }
`;

export const Body = styled.p`
  font-size: ${rem(16)};
  line-height: ${rem(24)};
  letter-spacing: ${rem(-0.1)};
  text-align: left;

  p:last-child {
    margin: 0;
  }
`;

export const AlignmentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
  width: 100%;

  ${({ alignment }) => {
    switch (alignment) {
      case 'top':
        return css`
          justify-content: flex-start;
        `;
      case 'center':
        return css`
          justify-content: center;
        `;
      case 'bottom':
        return css`
          justify-content: flex-end;
        `;
      default:
        return css`
          justify-content: flex-start;
        `;
    }
  }}
`;

export const Buttons = styled.div`
  margin-top: ${({ theme }) => theme.spacing.md};
  display: flex;
  flex-wrap: wrap;
`;
