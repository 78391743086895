import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Underline,
  Flex,
  ShowBreakpoint,
  HideBreakpoint,
  useIsMedia,
} from '@strikelabs/luna';
import { formatCurrency } from '@strikelabs/pax';

import TrustpilotStars from 'components/Icons/TrustpilotStars';

import { Tile, SlideText, SavingsText, Avatar, DetailsText } from '../style';

const Review = ({
  backgroundColor,
  avatar,
  savings,
  heading,
  quote,
  author,
  location,
  customerType,
  date,
  rating,
}) => {
  const { isMobile } = useIsMedia();

  return (
    <Tile backgroundColor={backgroundColor} addMargin={!!avatar}>
      {avatar && <Avatar src={avatar} />}
      {(author || location) && (
        <Flex
          width="100%"
          flexDirection="column"
          alignItems="center"
          mt={[2, 2, 4, 0]}
        >
          {author && <DetailsText>{author}</DetailsText>}
          {location && (
            <DetailsText textColor={backgroundColor}>{location}</DetailsText>
          )}
          {customerType && (
            <DetailsText textColor={backgroundColor}>
              {customerType}
            </DetailsText>
          )}
          {date && (
            <DetailsText textColor={backgroundColor}>{date}</DetailsText>
          )}
        </Flex>
      )}
      <Flex flexDirection="column" justifyContent="space-between" mt={2}>
        <Box my={[null, 1]}>
          <HideBreakpoint breakpoint="tablet">
            {rating && <TrustpilotStars width="90px" />}
          </HideBreakpoint>
          <ShowBreakpoint breakpoint="tablet">
            {rating && <TrustpilotStars width="120px" />}
          </ShowBreakpoint>
        </Box>
        {heading && (
          <Box mt={[1, 0, 1, 1]}>
            <SlideText as={isMobile && !!quote ? 'h3' : 'h2'}>
              {heading}
            </SlideText>
          </Box>
        )}
        {quote && (
          <Box mt={[1, 2, 2, 2]}>
            <SlideText styleType={isMobile && 'intro'}>{quote}</SlideText>
          </Box>
        )}
        {savings && (
          <Flex mt={[1, 2, 2, 2]} alignItems="baseline">
            <SavingsText>Saved:</SavingsText>
            <SavingsText ml={0} enlarge>
              <Underline>{formatCurrency(savings)}</Underline>
            </SavingsText>
          </Flex>
        )}
      </Flex>
    </Tile>
  );
};

Review.propTypes = {
  backgroundColor: PropTypes.oneOf(['green', 'black', 'white', 'grey']),
  customerType: PropTypes.oneOf(['seller', 'buyer']),
  date: PropTypes.string,
  author: PropTypes.string,
  location: PropTypes.string,
  avatar: PropTypes.string,
  savings: PropTypes.number,
  heading: PropTypes.string,
  quote: PropTypes.string,
  rating: PropTypes.number,
};

Review.defaultProps = {
  backgroundColor: 'grey',
  rating: 5,
};

export default Review;
