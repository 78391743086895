import React from 'react';
import PropTypes from 'prop-types';
import { AuthProvider } from '@strikelabs/vega';

import SEO from 'components/SEO';
import renderSections from 'utils/renderSections';
import { HeroSection } from 'components/CMS';

import { Spacer, ImageSpacer } from './style';
import Layout from './DynamicLayout';

const Page = ({
  pageContext: {
    title,
    metaDescription,
    metaTitle,
    canonical,
    body,
    noIndex,
    heroImage,
    metaImage,
    intro,
    heroCta,
    trustpilotData,
  },
}) => {
  const primaryCta = heroCta
    ? { url: heroCta[0].ctaUrl, label: heroCta[0].ctaText }
    : null;
  const secondaryCta =
    heroCta && heroCta[1]
      ? { url: heroCta[1].ctaUrl, label: heroCta[1].ctaText }
      : null;

  return (
    <AuthProvider baseUrl={process.env.GATSBY_API_URL}>
      <Layout>
        <HeroSection
          headline={title}
          intro={intro}
          image={heroImage}
          primaryCta={primaryCta}
          secondaryCta={secondaryCta}
        />
        {heroImage ? <ImageSpacer /> : <Spacer />}
        <SEO
          title={metaTitle || title}
          description={metaDescription}
          canonical={canonical}
          noIndex={noIndex}
          metaImage={metaImage}
        />
        {renderSections({ sections: body, trustpilotData })}
      </Layout>
    </AuthProvider>
  );
};

Page.propTypes = {
  pageContext: PropTypes.shape({
    title: PropTypes.string,
    metaDescription: PropTypes.string,
    metaTitle: PropTypes.string,
    noIndex: PropTypes.bool,
    intro: PropTypes.string,
    metaImage: PropTypes.string,
    heroImage: PropTypes.string,
    canonical: PropTypes.string,
    body: PropTypes.any,
    trustpilotData: PropTypes.shape({}),
    heroCta: PropTypes.shape({
      text: PropTypes.string,
      url: PropTypes.string,
    }),
  }),
};

Page.defaultProps = {
  pageContext: {
    metaDescription: null,
    metaTitle: null,
  },
};

export default Page;
