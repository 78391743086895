import styled from 'styled-components';
import { rem } from 'polished';

export const ImageSpacer = styled.div`
  ${({ theme }) => theme.media.desktop} {
    margin: ${rem(200)};
  }
`;

export const Spacer = styled.div`
  ${({ theme }) => theme.media.desktop} {
    margin: ${rem(50)};
  }
`;
